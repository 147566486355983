import React, { useState, useRef } from "react";

import {
  TextField,
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  LinearProgress,
} from "@material-ui/core";
import * as yup from "yup";
import { useFormik } from "formik";

import InputMask from "react-input-mask";

import useStyles from "./styles";
import axios from "config/axios";
import { setUserToken } from "functions/userToken";
import useMobile from "use-mobile-detect-hook";

import { useHistory } from "react-router-dom";

import CustomAlert from "components/CustomAlert";
import isCPF from "functions/isCPF";

import firebase from "config/firebase";

import logo from "assets/img/bouncer_extended_white.svg";
import loginGoogleImage from "assets/img/auth/btn_google.png";

yup.addMethod(yup.string, "isCPF", function (message) {
  return this.test("isCPF", message, function (value) {
    const { path, createError } = this;
    if (!isCPF(value || "")) {
      return createError({ path, message });
    }
    return true;
  });
});

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Entre um email válido")
    .required("O email é obrigatório"),
  password: yup.string().required("A senha é obrigatória"),
});

const completeRegisterSchema = yup.object().shape({
  name: yup.string().min(2, "Muito curto").required("O nome é Obrigatório"),
  email: yup.string().email("Email inválido").required("O email é Obrigatório"),
  cpf: yup.string().required("Entre com o seu CPF").isCPF("CPF inválido"),
  cellphone: yup.string().phone("BR", true, "Telefone inválido"),
  checkbox: yup.boolean().isTrue("Para continuar aceite os termos"),
});

function Login() {
  const [loading, setLoading] = useState(false),
    [register, setRegister] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const mobile = useMobile();

  const alertRef = useRef<any>();

  const loginGoogle = async () => {
    setLoading(true);
    firebase
      .googleLogin()
      .then(async (val) => {
        axios
          .post("/login", {
            firebaseToken: await val?.user?.getIdToken(true),
          })
          .then(({ data }) => {
            setLoading(false);
            setUserToken(data.token);
            history.push("/dashboard/places");
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            window.scrollTo(0, 0);
            switch (error?.response?.data?.status) {
              case 404: {
                formikFinish.setValues({
                  name: val.user?.displayName || "",
                  email: val.user?.email || "",
                  uid: val.user?.uid || "",
                  cellphone: val.user?.phoneNumber || "",
                  checkbox: false,
                  cpf: "",
                });
                setRegister(true);
                break;
              }
              default:
                alertRef?.current.alterAlert(
                  "Problema ao entrar com o google",
                  "error"
                );
                break;
            }
          });
      })
      .catch((error) => {
        console.log(error, "fire");
        setLoading(false);
        window.scrollTo(0, 0);
        switch (error?.code) {
          case "auth/unauthorized-domain":
            alertRef?.current.alterAlert("Domínio não autorizado", "error");
            break;
          case "auth/cancelled-popup-request":
            return;
          case "auth/popup-closed-by-user":
            return;
          case "auth/network-request-failed":
            alertRef?.current.alterAlert(
              "Se conecte a internet para fazer login",
              "warning"
            );
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao entrar com o google",
              "error"
            );
            break;
        }
      });
  };

  const completeGoogleLogin = async (values: any) => {
    let realCell = values.cellphone.replace(/\D/g, "");
    setLoading(true);
    axios
      .post("/finish/login", {
        email: values.email,
        name: values.name,
        cellphone: "+55" + realCell,
        firebaseUid: values.uid,
        cpf: isCPF(values.cpf),
      })
      .then(({ data }) => {
        setLoading(false);
        setUserToken(data.token);
        history.push("/dashboard/places");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            alertRef?.current.alterAlert(
              "Problema ao entrar com o google, tente novamente",
              "error"
            );
            break;
        }
      });
  };

  const firebaseLogin = async (values: { email: string; password: string }) => {
    setLoading(true);
    firebase
      .emailLogin(values.email, values.password)
      .then(async (val) => {
        if (!val?.user?.emailVerified) {
          setLoading(false);
          return alertRef?.current.alterAlert(
            "Email não verificado",
            "warning"
          );
        }
        axios
          .post("/login", {
            firebaseToken: await val?.user?.getIdToken(true),
          })
          .then(({ data }) => {
            setLoading(false);
            setUserToken(data.token);
            history.push("/dashboard/places");
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            window.scrollTo(0, 0);
            switch (error?.response?.data?.status) {
              default:
                alertRef?.current.alterAlert(
                  "Problema ao entrar, tente novamente. CODE: 1",
                  "warning"
                );
                break;
            }
          });
      })
      .catch((error) => {
        console.log(error, "fire");
        setLoading(false);
        window.scrollTo(0, 0);
        switch (error?.code) {
          case "auth/wrong-password":
            alertRef?.current.alterAlert("Senha incorreta", "warning");
            break;
          case "auth/user-not-found":
            alertRef?.current.alterAlert(
              "Usuário não encontrado! CADASTRE-SE no Bouncer",
              "warning"
            );
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao entrar, tente novamente. CODE: 2",
              "error"
            );
            break;
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      firebaseLogin(values);
    },
  });

  const formikFinish = useFormik({
    initialValues: {
      cellphone: "",
      name: "",
      email: "",
      uid: "",
      cpf: "",
      checkbox: false,
    },
    validationSchema: completeRegisterSchema,
    onSubmit: (values) => {
      completeGoogleLogin(values);
    },
  });

  return (
    <div>
      {loading && <LinearProgress />}
      <CustomAlert ref={alertRef} />
      <div className={classes.gradient}>
        {!mobile.isMobile() && (
          <img className={classes.logo} src={logo} alt="Logo Bouncer" />
        )}
        <Card className={classes.leftSide} style={{ borderRadius: 0 }}>
          <CardContent className={classes.cardContent}>
            {register ? (
              <>
                <Typography variant="h4" className={classes.title}>
                  Só mais um pouco
                </Typography>
                <form onSubmit={formikFinish.handleSubmit}>
                  <InputMask
                    mask="999.999.999-99"
                    type="text"
                    name="cpf"
                    onChange={formikFinish.handleChange}
                  >
                    {() => (
                      <TextField
                        fullWidth
                        label="Seu CPF"
                        style={{ marginTop: "5%" }}
                        error={
                          formikFinish.touched.cpf &&
                          Boolean(formikFinish.errors.cpf)
                        }
                        helperText={
                          formikFinish.touched.cpf && formikFinish.errors.cpf
                        }
                        name="cpf"
                        inputProps={{ style: { padding: 13 } }}
                      />
                    )}
                  </InputMask>
                  <InputMask
                    mask="(99) 99999-9999"
                    type="text"
                    name="cellphone"
                    onChange={formikFinish.handleChange}
                  >
                    {() => (
                      <TextField
                        fullWidth
                        label="Seu celular"
                        style={{ marginTop: "5%" }}
                        name="cellphone"
                        error={
                          formikFinish.touched.cellphone &&
                          Boolean(formikFinish.errors.cellphone)
                        }
                        helperText={
                          formikFinish.touched.cellphone &&
                          formikFinish.errors.cellphone
                        }
                        inputProps={{ style: { padding: 13 } }}
                      />
                    )}
                  </InputMask>
                  <FormControlLabel
                    style={{ marginTop: "5%" }}
                    control={
                      <Checkbox
                        checked={formikFinish.values.checkbox}
                        onChange={formikFinish.handleChange}
                        name="checkbox"
                        color="primary"
                        inputProps={{ "aria-label": "Termos de uso" }}
                      />
                    }
                    label={
                      <Typography>
                        Li e aceito os{" "}
                        <a
                          href="https://bouncer.com.br/termos/termos_de_uso.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          termos de uso
                        </a>
                      </Typography>
                    }
                  />
                  <FormHelperText
                    error={
                      formikFinish.touched.checkbox &&
                      Boolean(formikFinish.errors.checkbox)
                    }
                  >
                    {formikFinish.touched.checkbox &&
                      formikFinish.errors.checkbox}
                  </FormHelperText>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    style={{ marginTop: 15 }}
                  >
                    Finalizar cadastro
                  </Button>
                </form>
              </>
            ) : (
              <>
                <Typography variant="h4" className={classes.title}>
                  Bem-vindo
                </Typography>
                <div style={{ textAlign: "center", marginTop: 10 }}>
                  <Button
                    className={classes.btnGoogleContainer}
                    size="small"
                    onClick={loginGoogle}
                  >
                    <img
                      src={loginGoogleImage}
                      className={classes.btnGoogle}
                      alt="Login com google"
                    />
                  </Button>
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    style={{ marginTop: "5%" }}
                    inputProps={{ style: { padding: 13 } }}
                  />
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Senha"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    style={{ marginTop: "5%" }}
                    inputProps={{ style: { padding: 13 } }}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    style={{ marginTop: 15 }}
                  >
                    Entrar
                  </Button>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      color="primary"
                      onClick={() => {
                        history.push("/register");
                      }}
                      style={{ marginTop: 15 }}
                    >
                      Crie sua conta
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        history.push("/forgot/password");
                      }}
                      style={{ marginTop: 15 }}
                    >
                      Esqueceu a senha?
                    </Button>
                  </div>
                </form>
              </>
            )}
          </CardContent>
          <CardActions></CardActions>
        </Card>
      </div>
    </div>
  );
}

export default Login;
