import React, { useRef } from "react";

import MaterialTable from "material-table";

import axios from "config/axios";
import { AlertSeverity } from "types";

//import useStyles from "./styles";

interface Props {
  selectedPlace: string;
  alterAlert: (msg: string, severity: AlertSeverity) => void;
}

const PlaceVisitors: React.FC<Props> = (props) => {
  //const classes = useStyles();

  const { alterAlert, selectedPlace } = props;

  const tableRef = useRef<any>();
  const tableRef2 = useRef<any>();

  const procHour = (value: number) => {
    value = value - new Date().getTimezoneOffset() / 60;
    const hour = Math.trunc(value);
    let minutes = ((value - hour) * 60).toFixed(0);
    //Verifiqua se o valor dos minutos é igual a 0
    minutes = minutes === "0" ? "00" : minutes;
    return `${hour}:${minutes}`;
  };

  const procDays = (value: number[]) => {
    const days: string[] = [];
    value.forEach((e) => {
      let pushVal = "";
      switch (e) {
        case 0:
          pushVal = "Dom.";
          break;
        case 1:
          pushVal = "Seg.";
          break;
        case 2:
          pushVal = "Ter.";
          break;
        case 3:
          pushVal = "Qua.";
          break;
        case 4:
          pushVal = "Qui.";
          break;
        case 5:
          pushVal = "Sex.";
          break;
        case 6:
          pushVal = "Sab.";
          break;
        default:
          pushVal = "Dom.";
          break;
      }
      days.push(pushVal);
    });
    return <b>{days.join(" , ")}</b>;
  };

  return (
    <div>
      <MaterialTable
        options={{ exportButton: true, grouping: true, search: false }}
        tableRef={tableRef}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recarregar dados",
            isFreeAction: true,
            onClick: () =>
              tableRef.current && tableRef?.current?.onQueryChange(),
          },
        ]}
        style={{ fontFamily: "Nunito Sans" }}
        columns={[
          {
            title: "Pessoa",
            field: "user.name",
            type: "string",
          },
          {
            title: "Unidade",
            field: "board.name",
            type: "string",
          },
          {
            title: "Início",
            field: "start",
            type: "datetime",
            dateSetting: { format: "DD/MM/YYYY" },
          },
          {
            title: "Fim",
            field: "end",
            type: "datetime",
            dateSetting: { format: "DD/MM/YYYY HH:mm" },
          },
          {
            title: "Adicionado por",
            field: "addedBy.name",
            type: "string",
          },
          {
            title: "Usado",
            field: "used",
            type: "boolean",
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let { page, pageSize, orderBy, orderDirection, search } = query;
            axios
              .post(`/tempUser/place/list`, {
                page,
                pageSize,
                orderBy,
                orderDirection,
                search,
                placeId: selectedPlace,
              })
              .then(({ data }) => {
                resolve({
                  data: data.docs,
                  page: data.page - 1,
                  totalCount: data.totalDocs,
                });
              })
              .catch((error) => {
                switch (error.response.data.code) {
                  default:
                    props.alterAlert("Problema ao carregar dados", "error");
                    break;
                }
                reject("Problema ao carregar dados");
              });
          })
        }
        editable={{
          onRowDelete: async (oldData: any) => {
            try {
              await axios.delete(`/tempUser/del/${oldData._id}`);
              alterAlert("Visitante removido com sucesso", "success");
            } catch (error) {
              console.log(error);
              switch (error?.response?.data?.status) {
                case 404:
                  alterAlert("Visitante não encontrado", "warning");
                  break;
                case 2:
                  alterAlert("Local não encontrado", "warning");
                  break;
                case 401:
                  alterAlert("Sem permissão", "warning");
                  break;

                default:
                  alterAlert("Problema ao remover Visitante", "error");
                  break;
              }
            }
          },
        }}
        localization={{
          header: {
            actions: "Ações",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "registros",
            labelRowsPerPage: "Registros por página:",
            firstAriaLabel: "Primeira",
            firstTooltip: "Primeira",
            previousAriaLabel: "Anterior",
            previousTooltip: "Anterior",
            nextAriaLabel: "Próxima",
            nextTooltip: "Próxima",
            lastAriaLabel: "Última",
            lastTooltip: "Última",
          },
          toolbar: {
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem visitantes",
            addTooltip: "Adicionar usuário",
            deleteTooltip: "Remover usuário",
            editTooltip: "Editar usuário",
            editRow: {
              deleteText: "Remover usuário?",
              cancelTooltip: "Cancelar",
              saveTooltip: "Confirmar",
            },
          },
          grouping: {
            groupedBy: "Agrupado por:",
            placeholder: "Arraste um cabeçalho para agrupar",
          },
        }}
        title="Visitantes"
      />
      <MaterialTable
        options={{ exportButton: true, grouping: true, search: false }}
        tableRef={tableRef2}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recarregar dados",
            isFreeAction: true,
            onClick: () =>
              tableRef2.current && tableRef2?.current?.onQueryChange(),
          },
        ]}
        style={{ fontFamily: "Nunito Sans", marginTop: "1%" }}
        columns={[
          {
            title: "Pessoa",
            field: "user.name",
            type: "string",
          },
          {
            title: "Unidade",
            field: "board.name",
            type: "string",
          },
          {
            title: "Início",
            field: "startHour",
            type: "string",
            render: (rowData) => {
              return procHour(rowData.startHour);
            },
          },
          {
            title: "Dias",
            field: "days",
            type: "string",
            render: (rowData) => {
              return procDays(rowData.days);
            },
          },
          {
            title: "Fim",
            field: "endHour",
            type: "string",
            render: (rowData) => {
              return procHour(rowData.endHour);
            },
          },
          {
            title: "Adicionado por",
            field: "addedBy.name",
            type: "string",
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let { page, pageSize, orderBy, orderDirection, search } = query;
            axios
              .post(`/recUser/place/list`, {
                page,
                pageSize,
                orderBy,
                orderDirection,
                search,
                placeId: selectedPlace,
              })
              .then(({ data }) => {
                resolve({
                  data: data.docs,
                  page: data.page - 1,
                  totalCount: data.totalDocs,
                });
              })
              .catch((error) => {
                switch (error.response.data.code) {
                  default:
                    props.alterAlert("Problema ao carregar dados", "error");
                    break;
                }
                reject("Problema ao carregar dados");
              });
          })
        }
        editable={{
          onRowDelete: async (oldData: any) => {
            try {
              await axios.delete(`/recUser/del/${oldData._id}`);
              alterAlert("Visitante removido com sucesso", "success");
            } catch (error) {
              console.log(error);
              switch (error?.response?.data?.status) {
                case 404:
                  alterAlert("Visitante não encontrado", "warning");
                  break;
                case 2:
                  alterAlert("Local não encontrado", "warning");
                  break;
                case 401:
                  alterAlert("Sem permissão", "warning");
                  break;

                default:
                  alterAlert("Problema ao remover Visitante", "error");
                  break;
              }
            }
          },
        }}
        localization={{
          header: {
            actions: "Ações",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "registros",
            labelRowsPerPage: "Registros por página:",
            firstAriaLabel: "Primeira",
            firstTooltip: "Primeira",
            previousAriaLabel: "Anterior",
            previousTooltip: "Anterior",
            nextAriaLabel: "Próxima",
            nextTooltip: "Próxima",
            lastAriaLabel: "Última",
            lastTooltip: "Última",
          },
          toolbar: {
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem usuários recorrentes",
            addTooltip: "Adicionar usuário",
            deleteTooltip: "Remover usuário",
            editTooltip: "Editar usuário",
            editRow: {
              deleteText: "Remover usuário?",
              cancelTooltip: "Cancelar",
              saveTooltip: "Confirmar",
            },
          },
          grouping: {
            groupedBy: "Agrupado por:",
            placeholder: "Arraste um cabeçalho para agrupar",
          },
        }}
        title="Usuários recorrentes"
      />
    </div>
  );
};

export default PlaceVisitors;
