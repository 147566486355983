import { makeStyles, createStyles } from "@material-ui/core/styles";

export const selectStyles = {
  option: (
    styles: any,
    {
      isDisabled,
      isFocused,
      isSelected,
    }: { isDisabled: boolean; isFocused: boolean; isSelected: boolean }
  ) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#FF4E32" : isFocused && "#505050",
      color: isSelected ? "white" : isFocused && "white",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
      },
    };
  },
};


const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      marginBottom: 10,
    },
    backArrowBtn: {
      backgroundColor: "white",
      marginBottom: 10,
    },
    cardTitle: {},
    inputs: {
      marginTop: 10,
    },
    tabsContainer: {
      backgroundColor: theme.palette.background.paper,
    },
    fab: {
      position: "fixed",
      top: "auto",
      right: 20,
      left: "auto",
      bottom: 20,
      margin: 0,
    },
    dialogTitle: {
      marginLeft: theme.spacing(2),
      flex: 1,
      fontWeight: 600,
    },
    center: {
      justifyContent: "center",
      textAlign: "center",
    },
  })
);
export default useStyles;
