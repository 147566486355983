import { Routes } from "types";
import { Security, Places, Visitors } from "views/dashboard";

import { Home, Lock, PeopleRounded } from "@material-ui/icons";

export const adminRoutes: Routes = [
  {
    path: "/places",
    name: "Locais",
    icon: Home,
    component: Places,
    layout: "/dashboard",
  },
  {
    path: "/visitors",
    name: "Seus visitantes",
    icon: PeopleRounded,
    component: Visitors,
    layout: "/dashboard",
  },
  {
    path: "/config",
    name: "Segurança",
    icon: Lock,
    component: Security,
    layout: "/dashboard",
  },
  /*  {
      path: "/list/deliveries",
      name: "Suas entregas",
      //rtlName: "لوحة القيادة",
      icon: "inventory_2",
      component: ListDeliveries,
      layout: "/admin",
    }, */
];
