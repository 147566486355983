import React, { useState, useEffect, useCallback } from "react";

import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  AppBar,
  Tabs,
  Tab,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import Alert from "components/Alert";
import TabPanel from "components/TabPanel";

import PlaceHistoric from "components/PlaceHistoric";
import PlaceUsers from "components/PlaceUsers";
import PlaceBoards from "components/PlaceBoards";
import PlaceVisitors from "components/PlaceVisitors";

import axios from "config/axios";
import { AlertSeverity, AdminPlaces } from "types";

import useStyles from "./styles";
import { ArrowBack } from "@material-ui/icons";

const Historic: React.FC = () => {
  const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>(),
    [adminPlaces, setPlaces] = useState<AdminPlaces>([]),
    [selectedPlace, setSelectedPlace] = useState(""),
    [showAlert, setShowAlert] = useState(false),
    [mode, setMode] = useState(0),
    [selectedTab, setSelectedTab] = useState(0),
    [alert, setAlert] = useState("");

  const classes = useStyles();

  const theme = useTheme();

  const handleAlertClose = () => {
    alterAlert();
  };

  const alterAlert = (msg?: string, severity?: AlertSeverity) => {
    if (showAlert && !msg && !severity) {
      setShowAlert(false);
    } else {
      setShowAlert(true);
      setAlert(msg || "");
      setAlertSeverity(severity);
    }
  };

  const getPlaces = async () => {
    axios
      .get("/user/admPlaces")
      .then(({ data }) => {
        setPlaces(data.adminPlaces);
      })
      .catch((error) => {
        alterAlert("Problema ao carregar locais", "error");
        console.log(error);
      });
  };

  useEffect(() => {
    getPlaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSelector = useCallback(() => {
    return (
      <div>
        <Typography variant="h5" className={classes.title}>
          Seus locais
        </Typography>
        {!adminPlaces ? (
          <Typography align="center" variant="h6">
            Problema ao carregar
          </Typography>
        ) : (
          adminPlaces.map((e, index) => (
            <Card style={index > 0 ? { marginTop: 10 } : {}} key={index}>
              <CardContent>
                <Typography variant="h6">{e.place.name}</Typography>
              </CardContent>
              <CardActions>
                <Button
                  color="primary"
                  onClick={() => {
                    setMode(1);
                    setSelectedPlace(e.place._id);
                  }}
                >
                  Selecionar
                </Button>
              </CardActions>
            </Card>
          ))
        )}
      </div>
    );
  }, [classes, adminPlaces]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const renderTabs = () => {
    return (
      <>
        <IconButton
          onClick={() => {
            setSelectedPlace("");
            setMode(0);
          }}
          className={classes.backArrowBtn}
        >
          <ArrowBack />
        </IconButton>
        <div className={classes.tabsContainer}>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Histórico" />
              <Tab label="Usuários" />
              <Tab label="Visitantes" />
              <Tab label="Unidades" />
            </Tabs>
          </AppBar>
          <div>
            <TabPanel value={selectedTab} index={0} dir={theme.direction}>
              <PlaceHistoric
                alterAlert={alterAlert}
                selectedPlace={selectedPlace}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={1} dir={theme.direction}>
              <PlaceUsers
                alterAlert={alterAlert}
                selectedPlace={selectedPlace}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={2} dir={theme.direction}>
              <PlaceVisitors
                alterAlert={alterAlert}
                selectedPlace={selectedPlace}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={3} dir={theme.direction}>
              <PlaceBoards
                alterAlert={alterAlert}
                selectedPlace={selectedPlace}
              />
            </TabPanel>
          </div>
        </div>
      </>
    );
  };

  const switchRender = () => {
    switch (mode) {
      case 0:
        return renderSelector();
      case 1:
        return renderTabs();
      default:
        return renderSelector();
    }
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showAlert}
        onClose={handleAlertClose}
        message={alert}
      >
        <Alert onClose={handleAlertClose} severity={alertSeverity}>
          {alert}
        </Alert>
      </Snackbar>
      {switchRender()}
    </div>
  );
};

export default Historic;
