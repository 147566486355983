import firebase from "firebase/app";

import "firebase/auth";
import "firebase/firebase-messaging";
import "firebase/performance";
import "firebase/firebase-analytics";

class Fire {
  constructor() {
    let firebaseConfig = {
      apiKey: "AIzaSyDyiAUoaazp0NeOkY1UgMgUwbzasqZ8WNA",
      authDomain: "bouncer-micro.firebaseapp.com",
      projectId: "bouncer-micro",
      storageBucket: "bouncer-micro.appspot.com",
      messagingSenderId: "980350455582",
      appId: "1:980350455582:web:b330bbf55f6d97e26dc30f",
      measurementId: "G-JCQLR8BSL5",
    };

    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    firebase.performance();
  }
  async googleLogin() {
    let provider = new firebase.auth.GoogleAuthProvider();
    /* Api para leitura dos números de telefone */
    //provider.addScope("https://www.googleapis.com/auth/user.phonenumbers.read");
    /* Lendo o gênero da pessoa */
    //provider.addScope("https://www.googleapis.com/auth/user.gender.read");
    /* Lendo os endereços dela */
    //provider.addScope("https://www.googleapis.com/auth/user.addresses.read");
    /* lendo a data de aniversário dela  */
    //provider.addScope("https://www.googleapis.com/auth/user.addresses.read");

    provider.setCustomParameters({
      redirect_uri: "https://use.bouncer.com.br",
    });

    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // The signed-in user info.
        let user = result.user;
        return { user };
        // ...
      });
  }
  async emailRegister(email: string, password: string) {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  }
  async sendVerifyEmail() {
    return firebase?.auth()?.currentUser?.sendEmailVerification();
  }
  async emailLogin(email: string, password: string) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((val) => {
        return val;
      });
  }
  async passwordReset(email: string) {
    return firebase.auth().sendPasswordResetEmail(email);
  }
}

export default new Fire();
