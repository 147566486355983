import Axios from "axios";
import { getUserToken } from "functions/userToken";

let host = "https://api.bouncer.com.br";

if (process.env.NODE_ENV === "development") {
  host = "http://localhost:8080";
  //host = "http://192.168.100.98:8080";
}

const api = Axios.create({
  baseURL: host,
  headers: {
    "User-Timezone": new Date().getTimezoneOffset(),
  },
});

api.interceptors.request.use(async (config) => {
  const token = getUserToken();
  if (token != null) {
    config.headers.authorization = `Bearer ${token}`;
  }
  return config;
});

/* api.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    // Do something with response error

    // You can even test for a response code
    // and try a new request before rejecting the promise
    if (error.response.status === 401) {
      const requestConfig = error.config;
      window.location.href = "/";
      return api(requestConfig);
    }
    return Promise.reject(error);
  }
); */
export default api;
