import React, { useRef, forwardRef } from "react";

import MaterialTable from "material-table";

import axios from "config/axios";
import { AlertSeverity } from "types";

//import useStyles from "./styles";
import { AddBox } from "@material-ui/icons";

import { isEmail } from "functions/isEmail";

interface Props {
  selectedPlace: string;
  alterAlert: (msg: string, severity: AlertSeverity) => void;
}

const PlaceUsers: React.FC<Props> = (props) => {
  //const classes = useStyles();

  const { alterAlert, selectedPlace } = props;

  const tableRef = useRef<any>();

  return (
    <div>
      <MaterialTable
        options={{
          exportButton: true,
          grouping: false,
          search: false,
        }}
        style={{ fontFamily: "Nunito Sans" }}
        icons={{
          Add: forwardRef((props, ref) => (
            <AddBox color="primary" {...props} ref={ref} />
          )),
        }}
        tableRef={tableRef}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recarregar dados",
            isFreeAction: true,
            onClick: () =>
              tableRef.current && tableRef?.current?.onQueryChange(),
          },
        ]}
        columns={[
          {
            title: "Nome",
            field: "user.name",
            type: "string",
            editable: "never",
          },
          {
            title: "Email",
            field: "user.email",
            type: "string",
            editable: "onAdd",
            validate: (data: any) => {
              return isEmail(data?.user?.email);
            },
          },
          {
            title: "Administrador",
            field: "admin",
            type: "boolean",
          },
          {
            hidden: true,
            title: "Relation",
            field: "_id",
            type: "string",
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let { page, pageSize, orderBy, orderDirection, search } = query;
            axios
              .post(`/place/users/`, {
                page,
                pageSize,
                orderBy,
                orderDirection,
                search,
                placeId: selectedPlace,
              })
              .then(({ data }) => {
                resolve({
                  data: data.docs,
                  page: data.page - 1,
                  totalCount: data.totalDocs,
                });
              })
              .catch((error) => {
                switch (error.response.data.code) {
                  default:
                    alterAlert("Problema ao carregar dados", "error");
                    break;
                }
                reject("Problema ao carregar dados");
              });
          })
        }
        editable={{
          onRowAdd: async (newData: any) => {
            try {
              await axios.post("/place/add/user", {
                email: newData?.user?.email,
                admin: newData.admin,
                placeId: selectedPlace,
              });
              alterAlert("Usuário cadastrado com sucesso", "success");
            } catch (error) {
              console.log(error);
              switch (error?.response?.data?.status) {
                case 404:
                  alterAlert("Usuário não encontrado", "warning");
                  break;
                case 401:
                  alterAlert("Usuário já cadastrado", "warning");
                  break;
                default:
                  alterAlert("Problema ao cadastrar usuário", "error");
                  break;
              }
            }
          },
          onRowUpdate: async (newData, oldData) => {
            try {
              await axios.put(`/place/update/user/status`, {
                admin: newData.admin,
                place_userId: oldData._id,
              });

              alterAlert("Atualizado com sucesso", "success");
            } catch (error) {
              console.log(error);
              switch (error?.response?.data?.status) {
                case 404:
                  alterAlert("Usuário não encontrado", "warning");
                  break;
                case 401:
                  alterAlert(
                    "Não é possível alterar a própria permissão",
                    "warning"
                  );
                  break;
                default:
                  alterAlert("Problema ao atualizar usuário", "error");
                  break;
              }
            }
          },
          onRowDelete: async (oldData) => {
            try {
              await axios.delete(
                `/place/del/user/${oldData.user._id}/${selectedPlace}`
              );
              alterAlert("Usuário removido com sucesso", "success");
            } catch (error) {
              console.log(error);
              switch (error?.response?.data?.status) {
                case 404:
                  alterAlert("Usuário não encontrado", "warning");
                  break;
                case 1:
                  alterAlert("Impossível se excluir", "warning");
                  break;
                case 2:
                  alterAlert("Local não encontrado", "warning");
                  break;
                case 401:
                  alterAlert("Sem permissão", "warning");
                  break;

                default:
                  alterAlert("Problema ao remover usuário", "error");
                  break;
              }
            }
          },
        }}
        localization={{
          header: {
            actions: "Ações",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "registros",
            labelRowsPerPage: "Registros por página:",
            firstAriaLabel: "Primeira",
            firstTooltip: "Primeira",
            previousAriaLabel: "Anterior",
            previousTooltip: "Anterior",
            nextAriaLabel: "Próxima",
            nextTooltip: "Próxima",
            lastAriaLabel: "Última",
            lastTooltip: "Última",
          },
          toolbar: {
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem informações",
            addTooltip: "Adicionar usuário",
            deleteTooltip: "Remover usuário",
            editTooltip: "Editar usuário",
            editRow: {
              deleteText: "Remover usuário?",
              cancelTooltip: "Cancelar",
              saveTooltip: "Confirmar",
            },
          },
          grouping: {
            groupedBy: "Agrupado por:",
            placeholder: "Arraste um cabeçalho para agrupar",
          },
        }}
        title="Usuários"
      />
    </div>
  );
};

export default PlaceUsers;
