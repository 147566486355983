import React, { useRef, useState } from "react";

import {
  Typography,
  Card,
  Grid,
  TextField,
  CardContent,
  CardActions,
  Button,
  Paper,
  IconButton,
  CircularProgress,
  Dialog,
  AppBar,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import InputMask from "react-input-mask";

import useStyles from "./styles";

import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";

import axios from "config/axios";
import CustomAlert from "components/CustomAlert";
import Transition from "components/Transition";

import {
  ArrowBack,
  ArrowRight,
  Delete,
  Close as CloseIcon,
  Add,
} from "@material-ui/icons";

import { EmergContacts } from "types";

const UpdatePwdSchema = Yup.object().shape({
  newPwd: Yup.number()
    .required("A senha é obrigatória")
    .min(6, "A senha deve ter 6 dígítos")
    .oneOf([Yup.ref("confNewPwd"), null], "As senhas devem ser iguais"),
  confNewPwd: Yup.number()
    .required("A senha é obrigatória")
    .min(6, "A senha deve ter 6 dígítos")
    .oneOf([Yup.ref("newPwd"), null], "As senhas devem ser iguais"),
});

const UpdateCoercionPwdSchema = Yup.object().shape({
  coercionNewPwd: Yup.number()
    .required("A senha é obrigatória")
    .min(6, "A senha deve ter 6 dígítos")
    .oneOf([Yup.ref("coercionConfNewPwd"), null], "As senhas devem ser iguais"),
  coercionConfNewPwd: Yup.number()
    .min(6, "A senha deve ter 6 dígítos")
    .required("A senha é obrigatória")
    .oneOf([Yup.ref("coercionNewPwd"), null], "As senhas devem ser iguais"),
});

const AddEmergSchema = Yup.object().shape({
  fullname: Yup.string()
    .min(2, "Muito curto")
    .required("O nome completo é obrigatório"),
  cellphone: Yup.string()
    .required("O celular é obrigatório")
    .phone("BR", true, "Celular inválido"),
});

const Config: React.FC = () => {
  const [mode, setMode] = useState(0),
    [loading, setLoading] = useState(false),
    [showModal, setShowModal] = useState<number | null>(null),
    [emergContacts, setEmergContacts] = useState<EmergContacts>([]);

  const classes = useStyles();

  const alertRef = useRef<any>();

  const formik = useFormik({
    initialValues: {
      newPwd: "",
      confNewPwd: "",
    },
    validationSchema: UpdatePwdSchema,
    onSubmit: (values) => {
      updatePwd(values);
    },
  });

  const formikCoercion = useFormik({
    initialValues: {
      coercionNewPwd: "",
      coercionConfNewPwd: "",
    },
    validationSchema: UpdateCoercionPwdSchema,
    onSubmit: (values) => {
      updateCoercionPwd(values);
    },
  });

  const updatePwd = async (values: { newPwd: string; confNewPwd: string }) => {
    axios
      .put("/user/alter/personal/pwd", {
        perPassword: values.newPwd,
      })
      .then(() => {
        alertRef?.current.alterAlert("Senha pessoal atualizada", "success");
        formik.resetForm();
      })
      .catch((error) => {
        console.log(error);
        switch (error.response.data.status) {
          case 1:
            alertRef?.current.alterAlert(
              "Tamanho da senha inválido",
              "warning"
            );
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao atualizar senha de pessoal",
              "error"
            );
            break;
        }
      });
  };

  const updateCoercionPwd = async (values: {
    coercionNewPwd: string;
    coercionConfNewPwd: string;
  }) => {
    axios
      .put("/user/alter/coercion/pwd", {
        coercionPassword: values.coercionNewPwd,
      })
      .then(() => {
        alertRef?.current.alterAlert("Senha de coação atualizada", "success");
        formikCoercion.resetForm();
      })
      .catch((error) => {
        console.log(error);
        switch (error.response.data.status) {
          case 1:
            alertRef?.current.alterAlert(
              "Tamanho da senha inválido",
              "warning"
            );
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao atualizar senha de coação",
              "error"
            );
            break;
        }
      });
  };

  const validatePwdSize = (e: React.ChangeEvent<any>) => {
    const value = e.target.value;
    if (value.length > 6) {
      return "";
    }
    const regra = /^[0-9]+$/;
    if (!value.match(regra) && value !== "") {
      return "";
    }
    return e;
  };

  const renderBaseContent = () => {
    return (
      <>
        <Typography variant="h5">Configurações de segurança</Typography>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
        >
          <Grid item style={{ marginTop: 10 }}>
            <Card>
              <CardContent>
                <Typography variant="h6" className={classes.cardTitle}>
                  Configurar senha pessoal
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Nova senha pessoal"
                  type="password"
                  name="newPwd"
                  value={formik.values.newPwd}
                  onChange={(e) => {
                    formik.handleChange(validatePwdSize(e));
                  }}
                  error={formik.touched.newPwd && Boolean(formik.errors.newPwd)}
                  helperText={formik.touched.newPwd && formik.errors.newPwd}
                  variant="outlined"
                  className={classes.inputs}
                  inputProps={{ style: { padding: 13 } }}
                />
                <TextField
                  fullWidth
                  placeholder="Confirme sua senha pessoal"
                  type="password"
                  name="confNewPwd"
                  value={formik.values.confNewPwd}
                  onChange={(e) => {
                    formik.handleChange(validatePwdSize(e));
                  }}
                  error={
                    formik.touched.confNewPwd &&
                    Boolean(formik.errors.confNewPwd)
                  }
                  helperText={
                    formik.touched.confNewPwd && formik.errors.confNewPwd
                  }
                  variant="outlined"
                  className={classes.inputs}
                  inputProps={{ style: { padding: 13 } }}
                />
              </CardContent>
              <CardActions style={{ justifyContent: "flex-end" }}>
                <Button
                  onClick={() => formik.handleSubmit()}
                  variant="contained"
                  color="secondary"
                >
                  Atualizar
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item style={{ marginTop: 10 }}>
            <Card>
              <CardContent>
                <Typography variant="h6" className={classes.cardTitle}>
                  Configurar senha de coação
                </Typography>
                <Typography variant="subtitle2" align="center" style={{color:"#d00"}}>
                  * As senhas de coação e pessoal devem ser diferentes
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Nova senha de coação"
                  type="password"
                  name="coercionNewPwd"
                  value={formikCoercion.values.coercionNewPwd}
                  onChange={(e) => {
                    formikCoercion.handleChange(validatePwdSize(e));
                  }}
                  error={
                    formikCoercion.touched.coercionNewPwd &&
                    Boolean(formikCoercion.errors.coercionNewPwd)
                  }
                  helperText={
                    formikCoercion.touched.coercionNewPwd &&
                    formikCoercion.errors.coercionNewPwd
                  }
                  variant="outlined"
                  className={classes.inputs}
                  inputProps={{ style: { padding: 13 } }}
                />
                <TextField
                  fullWidth
                  placeholder="Confirme a senha de coação"
                  type="password"
                  name="coercionConfNewPwd"
                  value={formikCoercion.values.coercionConfNewPwd}
                  onChange={(e) => {
                    formikCoercion.handleChange(validatePwdSize(e));
                  }}
                  error={
                    formikCoercion.touched.coercionConfNewPwd &&
                    Boolean(formikCoercion.errors.coercionConfNewPwd)
                  }
                  helperText={
                    formikCoercion.touched.coercionConfNewPwd &&
                    formikCoercion.errors.coercionConfNewPwd
                  }
                  variant="outlined"
                  className={classes.inputs}
                  inputProps={{ style: { padding: 13 } }}
                />
              </CardContent>
              <CardActions style={{ justifyContent: "flex-end" }}>
                <Button
                  onClick={() => formikCoercion.handleSubmit()}
                  variant="contained"
                  color="secondary"
                >
                  Atualizar
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item style={{ marginTop: 10 }}>
            <Paper style={{ padding: 10 }}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="body1" className={classes.cardTitle}>
                    Configurar contatos de emergência{" "}
                  </Typography>{" "}
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    endIcon={<ArrowRight />}
                    color="secondary"
                    onClick={() => {
                      getEmergContacts();
                      setMode(1);
                    }}
                  >
                    Configurar
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  };

  const getEmergContacts = async () => {
    setLoading((oldState) => !oldState);
    axios
      .get("/emerg/get/contacts")
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        setEmergContacts(data);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error.response.data.status) {
          case 1:
            alertRef?.current.alterAlert(
              "Tamanho da senha inválido",
              "warning"
            );
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao atualizar senha de coação",
              "error"
            );
            break;
        }
      });
  };

  const procCellphone = (val: string) => {
    try {
      const begining = val.substring(0, 2);
      const middle = val.substring(2, 7);
      const end = val.substring(7);
      return `(${begining}) ${middle}-${end}`;
    } catch (error) {
      console.log(error, val);
      return val;
    }
  };

  const addEmergContact = (values: { cellphone: string; fullname: string }) => {
    let realCell = values.cellphone.replace(/\D/g, "");
    setLoading((oldState) => !oldState);
    axios
      .post("/emerg/add/contact", {
        cellphone: realCell,
        fullname: values.fullname,
      })
      .then(() => {
        setLoading((oldState) => !oldState);
        setShowModal(null);
        getEmergContacts();
        formikEmerg.resetForm();
        alertRef?.current.alterAlert("Cadastrado com sucesso", "success");
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error.response.data.status) {
          case 1:
            alertRef?.current.alterAlert(
              "Limite de contatos atingido",
              "warning"
            );
            break;
          case 2:
            alertRef?.current.alterAlert(
              "Número de telefone já cadastrado",
              "warning"
            );
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao cadastrar contato de emergência",
              "error"
            );
            break;
        }
      });
  };

  const delEmergContact = async (_id: string) => {
    setLoading((oldState) => !oldState);
    axios
      .delete(`/emerg/del/contact/${_id}`)
      .then(() => {
        setLoading((oldState) => !oldState);
        getEmergContacts();
        alertRef?.current.alterAlert("Removido com sucesso", "success");
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error.response.data.status) {
          default:
            alertRef?.current.alterAlert(
              "Problema ao excluir contato de emergência",
              "error"
            );
            break;
        }
      });
  };

  const formikEmerg = useFormik({
    initialValues: {
      cellphone: "",
      fullname: "",
    },
    validationSchema: AddEmergSchema,
    onSubmit: (values) => {
      addEmergContact(values);
    },
  });

  const renderEmergencyContacts = () => {
    if (loading) {
      return (
        <>
          <IconButton
            className={classes.backArrowBtn}
            onClick={() => {
              setMode(0);
            }}
          >
            <ArrowBack />
          </IconButton>
          <div className={classes.center}>
            <CircularProgress />
          </div>
        </>
      );
    }

    return (
      <>
        <IconButton
          className={classes.backArrowBtn}
          onClick={() => {
            setMode(0);
          }}
        >
          <ArrowBack />
        </IconButton>
        <div style={{ marginBottom: 10 }} className={classes.center}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<Add />}
            onClick={() => {
              setShowModal(0);
            }}
            disabled={loading || emergContacts.length >= 5}
          >
            Adicionar contato
          </Button>
          {emergContacts.length >= 5 && (
            <Typography variant="body2">
              Limite de 5 contatos de emergência atingido
            </Typography>
          )}
        </div>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
          spacing={2}
        >
          {emergContacts.map((val, i) => (
            <Grid item key={i}>
              <Card>
                <CardContent>
                  <Typography>
                    Nome: <b>{val.fullname}</b>
                  </Typography>
                  <Typography>
                    Celular: <b>{procCellphone(val.cellphone)}</b>
                  </Typography>
                </CardContent>
                <CardActions style={{ justifyContent: "flex-end" }}>
                  <IconButton
                    onClick={() => {
                      delEmergContact(val._id);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Dialog
          fullScreen
          open={showModal === 0}
          onClose={() => {
            setShowModal(null);
            formikEmerg.resetForm();
          }}
          TransitionComponent={Transition}
        >
          <AppBar style={{ position: "relative" }}>
            <Toolbar>
              <Tooltip title="Fechar" aria-label="Fechar">
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    setShowModal(null);
                    formikEmerg.resetForm();
                  }}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <Typography variant="h6" className={classes.dialogTitle}>
                Adicionar contato de emergência
              </Typography>
            </Toolbar>
          </AppBar>
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="center"
            style={{ padding: 20 }}
            spacing={2}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="fullname"
                name="fullname"
                label="Nome completo do contato"
                value={formikEmerg.values.fullname}
                onChange={formikEmerg.handleChange}
                error={
                  formikEmerg.touched.fullname &&
                  Boolean(formikEmerg.errors.fullname)
                }
                helperText={
                  formikEmerg.touched.fullname && formikEmerg.errors.fullname
                }
              />
            </Grid>
            <Grid item xs={12}>
              <InputMask
                mask="(99) 99999-9999"
                type="text"
                name="cellphone"
                value={formikEmerg.values.cellphone}
                onChange={formikEmerg.handleChange}
              >
                {() => (
                  <TextField
                    fullWidth
                    label="Celular do contato"
                    name="cellphone"
                    error={
                      formikEmerg.touched.cellphone &&
                      Boolean(formikEmerg.errors.cellphone)
                    }
                    helperText={
                      formikEmerg.touched.cellphone &&
                      formikEmerg.errors.cellphone
                    }
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => formikEmerg.handleSubmit()}
                color="secondary"
                disabled={loading}
              >
                Adicionar
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      </>
    );
  };

  const switchRender = () => {
    switch (mode) {
      case 0:
        return renderBaseContent();
      case 1:
        return renderEmergencyContacts();
      default:
        return renderBaseContent();
    }
  };

  return (
    <div>
      <CustomAlert ref={alertRef} />
      {switchRender()}
    </div>
  );
};

export default Config;
