import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      marginBottom: 10,
    },
    backArrowBtn: {
      backgroundColor: "white",
      marginBottom: 10,
    },
    cardTitle: {
      fontSize: "clamp(18px, 5vw, 23px)",
    },
    inputs: {
      marginTop: 10,
    },
    dialogTitle: {
      marginLeft: theme.spacing(2),
      flex: 1,
      fontWeight: 600,
    },
    center: {
      justifyContent: "center",
      textAlign: "center",
    },
  })
);
export default useStyles;
