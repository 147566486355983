import React, { useRef } from "react";

import MaterialTable from "material-table";

import axios from "config/axios";
import { AlertSeverity } from "types";

//import useStyles from "./styles";

interface Props {
  selectedPlace: string;
  alterAlert: (msg: string, severity: AlertSeverity) => void;
}

const PlaceHistoric: React.FC<Props> = (props) => {
  //const classes = useStyles();

  const tableRef = useRef<any>();

  return (
    <div>
      <MaterialTable
        options={{ exportButton: true, grouping: true, search: true }}
        tableRef={tableRef}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recarregar dados",
            isFreeAction: true,
            onClick: () =>
              tableRef.current && tableRef?.current?.onQueryChange(),
          },
        ]}
        style={{ fontFamily: "Nunito Sans" }}
        columns={[
          {
            title: "Pessoa",
            field: "user.name",
            type: "string",
          },
          {
            title: "Horário",
            field: "createAt",
            type: "datetime",
            defaultSort: "desc",
          },
          {
            title: "Unidade",
            field: "board.name",
            type: "string",
          },
          {
            title: "Visitante",
            field: "isTempUser",
            type: "boolean",
            hidden: true,
          },
          {
            title: "Recorrente",
            field: "isRecUser",
            type: "boolean",
            hidden: true,
          },
          {
            title: "Tipo",
            render: (rowData) => {
              if (rowData.isRecUser) {
                return "Recorrente";
              }
              if (rowData.isTempUser) {
                return "Visitante";
              }
              return "Normal";
            },
            type: "string",
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let { page, pageSize, orderBy, orderDirection, search } = query;
            axios
              .post(`/historic/find/`, {
                page,
                pageSize,
                orderBy,
                orderDirection,
                search,
                placeId: props.selectedPlace,
              })
              .then(({ data }) => {
                resolve({
                  data: data.docs,
                  page: data.page - 1,
                  totalCount: data.totalDocs,
                });
              })
              .catch((error) => {
                switch (error.response.data.code) {
                  default:
                    props.alterAlert("Problema ao carregar dados", "error");
                    break;
                }
                reject("Problema ao carregar dados");
              });
          })
        }
        localization={{
          header: {
            actions: "Ações",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "registros",
            labelRowsPerPage: "Registros por página:",
            firstAriaLabel: "Primeira",
            firstTooltip: "Primeira",
            previousAriaLabel: "Anterior",
            previousTooltip: "Anterior",
            nextAriaLabel: "Próxima",
            nextTooltip: "Próxima",
            lastAriaLabel: "Última",
            lastTooltip: "Última",
          },
          toolbar: {
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem informações",
          },
          grouping: {
            groupedBy: "Agrupado por:",
            placeholder: "Arraste um cabeçalho para agrupar",
          },
        }}
        title="Histórico de uso"
      />
    </div>
  );
};

export default PlaceHistoric;
