import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    tabsContainer: {
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      marginBottom: 10,
    },
    backArrowBtn: {
      backgroundColor: "white",
      marginBottom: 10,
    },
  })
);
export default useStyles;
