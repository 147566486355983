import React, { useCallback, useState, useEffect } from "react";

import MaterialTable from "material-table";

import axios from "config/axios";
import { AlertSeverity, Boards } from "types";

import download from "downloadjs";

//import useStyles from "./styles";

interface Props {
  selectedPlace: string;
  alterAlert: (msg: string, severity: AlertSeverity) => void;
}

const PlaceBoards: React.FC<Props> = (props) => {
  const { alterAlert, selectedPlace } = props;
  const [boards, setBoards] = useState<Boards>([]),
    [loading, setLoading] = useState(false);

  const getBoards = useCallback(async () => {
    setLoading((oldState) => !oldState);
    axios
      .get(`/place/boards/${selectedPlace}`)
      .then(({ data }: { data: Boards }) => {
        setLoading((oldState) => !oldState);
        setBoards(data);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        switch (error.response.data.code) {
          default:
            alterAlert("Problema ao carregar dados", "error");
            break;
        }
      });
  }, [alterAlert, selectedPlace]);

  useEffect(() => {
    getBoards();
  }, [getBoards]);

  const downloadQr = async (_id: string) => {
    setLoading((oldState) => !oldState);
    axios
      .get(`/board/qr/${_id}`, {
        responseType: "blob",
      })
      .then((val) => {
        setLoading((oldState) => !oldState);
        let { data, headers } = val;
        let contentType = headers["content-type"],
          split = contentType.split(";");
        download(data, split[1].substring(11, 32), split[0]);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        switch (error.response.data.code) {
          default:
            alterAlert("Problema ao baixar QR Code", "error");
            break;
        }
      });
  };

  return (
    <div>
      <MaterialTable
        options={{ exportButton: true, grouping: false, search: false }}
        style={{ fontFamily: "Nunito Sans" }}
        actions={[
          {
            icon: "download",
            tooltip: "Baixar qr code",
            onClick: (event, rowData: any) => downloadQr(rowData._id),
          },
        ]}
        columns={[
          {
            title: "Nome",
            field: "name",
            type: "string",
          },
          {
            title: "Informações",
            field: "info",
            type: "string",
          },
          {
            title: "Número serial",
            field: "serial",
            type: "string",
          },
        ]}
        data={boards}
        isLoading={loading}
        localization={{
          header: {
            actions: "Ações",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "registros",
            labelRowsPerPage: "Registros por página:",
            firstAriaLabel: "Primeira",
            firstTooltip: "Primeira",
            previousAriaLabel: "Anterior",
            previousTooltip: "Anterior",
            nextAriaLabel: "Próxima",
            nextTooltip: "Próxima",
            lastAriaLabel: "Última",
            lastTooltip: "Última",
          },
          toolbar: {
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem informações",
          },
          grouping: {
            groupedBy: "Agrupado por:",
            placeholder: "Arraste um cabeçalho para agrupar",
          },
        }}
        title="Unidades"
      />
    </div>
  );
};

export default PlaceBoards;
