import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import Select from "react-select";

import {
  Typography,
  Card,
  Grid,
  TextField,
  CardContent,
  CardActions,
  Button,
  AppBar,
  Tab,
  Tabs,
  IconButton,
  Snackbar,
  Fab,
  Tooltip,
  Toolbar,
  Dialog,
  Zoom,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
//import useMobileDetect from "use-mobile-detect-hook";

import { days_of_the_week } from "constants/days_of_the_week";

import moment from "moment";

import TabPanel from "components/TabPanel";
import Alert from "components/Alert";

import useStyles, { selectStyles } from "./styles";

import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";

import axios from "config/axios";
import { Add as AddIcon, Close as CloseIcon, Delete } from "@material-ui/icons";

import {
  RecUsers,
  TempUsers,
  AlertSeverity,
  User_places,
  SelectValues,
  Boards,
} from "types";
import Transition from "components/Transition";

const yupTemp = Yup.object({
  email: Yup.string()
    .email("Entre um email inválido")
    .required("O email é obrigatório"),
  board: Yup.object().required("Selecione uma unidade").nullable(),
  place: Yup.string().required("Selecione um local"),
});

const yupRec = Yup.object({
  email: Yup.string()
    .email("Entre um email inválido")
    .required("O email é obrigatório"),
  board: Yup.object().nullable().required("Selecione uma unidade"),
  place: Yup.string().required("Selecione um local"),
  days: Yup.array().min(1, "Selecione pelo menos 1 dia"),
  endTime: Yup.string()
    .required("Horário de término é obrigatório")
    .nullable()
    .notOneOf([Yup.ref("startTime")], "Os horários não podem ser iguais"),
  startTime: Yup.string()
    .required("Horário de início é obrigatório")
    .nullable()
    .notOneOf([Yup.ref("endTime")], "Os horários não podem ser iguais"),
});

const Visitors: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0),
    [loading, setLoading] = useState(false),
    [tempUsers, setTempUsers] = useState<TempUsers>([]),
    [showAlert, setShowAlert] = useState(false),
    [alert, setAlert] = useState(""),
    [alertSeverity, setAlertSeverity] = useState<AlertSeverity>(),
    [showModal, setShowModal] = useState<number | null>(null),
    [places, setPlaces] = useState<SelectValues>([]),
    [boards, setBoards] = useState<SelectValues>([]),
    [recUsers, setRecUsers] = useState<RecUsers>([]);

  const classes = useStyles();
  const theme = useTheme();
  //const detectMobile = useMobileDetect();

  const alterAlert = (msg?: string, severity?: AlertSeverity) => {
    if (showAlert && !msg && !severity) {
      setShowAlert(false);
    } else {
      setShowAlert(true);
      setAlert(msg || "");
      setAlertSeverity(severity);
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      const rec = await axios.post("/recUser/user/list");
      setRecUsers(rec.data);
      const temp = await axios.post("/tempUser/user/list");
      setTempUsers(temp.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      alterAlert("Problema ao carregar informações", "error");
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Métodos tempUser

  const delTempUser = async (id: string) => {
    setLoading((oldState) => !oldState);
    axios
      .delete(`/tempUser/del/${id}`)
      .then(() => {
        setLoading((oldState) => !oldState);
        alterAlert("Visitante removido com sucesso", "success");
        getData();
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data.status) {
          case 404:
            alterAlert("Visitante não encontrado", "warning");
            break;
          default:
            alterAlert("Problema ao exluir visitante", "error");
            break;
        }
      });
  };

  const formikTemp = useFormik({
    initialValues: {
      place: undefined,
      board: undefined,
      email: "",
    },
    validationSchema: yupTemp,
    onSubmit: (values) => {
      addTempUser(values);
    },
  });

  const addTempUser = async (values: any) => {
    setLoading((oldState) => !oldState);
    axios
      .post("/tempUser/add", {
        email: values.email,
        boardId: values?.board?.value,
      })
      .then(() => {
        setLoading((oldState) => !oldState);
        alterAlert("Visitante cadastrado com sucesso", "success");
        setShowModal(null);
        formikTemp.resetForm();
        getData();
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data.status) {
          case 404:
            alterAlert("Usuário com esse email não encontrado", "warning");
            break;
          case 1:
            alterAlert("Você não faz parte do grupo", "error");
            break;
          case 2:
            alterAlert("Não é possível se cadastrar", "warning");
            break;
          case 3:
            alterAlert("Visitante atualizado", "success");
            break;
          case 4:
            alterAlert(
              "Visitante já faz parte de um grupo com a unidade",
              "warning"
            );
            break;
          default:
            alterAlert("Problema ao adicionar visitante", "error");
            break;
        }
      });
  };

  const loadPlaces = async () => {
    setLoading((oldState) => !oldState);
    axios
      .get(`/user/places`)
      .then(({ data }: { data: User_places }) => {
        setLoading((oldState) => !oldState);
        let result: any = [];
        data.places.forEach((element) => {
          result.push({ label: element.place.name, value: element.place._id });
        });
        setPlaces(result);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data.status) {
          default:
            alterAlert("Problema ao carregar locais", "error");
            break;
        }
      });
  };

  const loadBoards = async (placeId?: string) => {
    if (!placeId) {
      return;
    }
    setLoading(true);
    axios
      .get(`/place/boards/${placeId}`)
      .then(({ data }: { data: Boards }) => {
        setLoading(false);
        let result: any = [];
        data.forEach((element) => {
          result.push({ label: element.name, value: element._id });
        });
        setBoards(result);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        switch (error?.response?.data.status) {
          default:
            alterAlert("Problema ao carregar locais", "error");
            break;
        }
      });
  };

  //Métodos usuários recorrentes
  const formikRec = useFormik({
    initialValues: {
      place: undefined,
      board: undefined,
      email: "",
      startTime: new Date(1617073230000),
      endTime: new Date(1617073230000),
      days: [],
    },
    validationSchema: yupRec,
    onSubmit: (values) => {
      addRecUser(values);
    },
  });

  const addRecUser = async (values: any) => {
    setLoading((oldState) => !oldState);
    const start = values.startTime.split(":"),
      end = values.endTime.split(":");

    let startHour = parseInt(start[0]) + start[1] / 60,
      endHour = parseInt(end[0]) + end[1] / 60;

    if (endHour < startHour) {
      return formikRec.setFieldError(
        "endTime",
        "A hora final deve ser maior que inicial"
      );
    }

    const fuse = new Date().getTimezoneOffset() / 60;

    startHour = startHour + fuse;
    endHour = endHour + fuse;
    axios
      .post("/recUser/add", {
        email: values.email,
        boardId: values?.board?.value,
        days: values.days,
        startHour,
        endHour,
      })
      .then(() => {
        setLoading((oldState) => !oldState);
        alterAlert("Usuário recorrente cadastrado com sucesso", "success");
        getData();
        setShowModal(null);
        formikRec.resetForm();
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data.status) {
          case 404:
            alterAlert("Usuário com esse email não encontrado", "warning");
            break;
          case 1:
            alterAlert("Você não faz parte do grupo", "error");
            break;
          case 2:
            alterAlert("Não é possível se cadastrar", "warning");
            break;
          case 3:
            alterAlert("Visitante atualizado", "success");
            break;
          case 4:
            alterAlert(
              "Visitante já faz parte de um grupo com a unidade",
              "warning"
            );
            break;
          default:
            alterAlert("Problema ao adicionar usuário recorrente", "error");
            break;
        }
      });
  };

  const delRecUser = async (id: string) => {
    setLoading((oldState) => !oldState);
    axios
      .delete(`/recUser/del/${id}`)
      .then(() => {
        setLoading((oldState) => !oldState);
        alterAlert("Usuário recorrente removido com sucesso", "success");
        getData();
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data.status) {
          case 404:
            alterAlert("Usuário recorrente não encontrado", "warning");
            break;
          default:
            alterAlert("Problema ao exluir visitante", "error");
            break;
        }
      });
  };

  const renderVisitors = () => {
    if (loading) {
      return (
        <div className={classes.center}>
          <CircularProgress />
        </div>
      );
    }

    if (tempUsers.length === 0) {
      return (
        <Typography align="center" variant="h6">
          Nenhum visitante
        </Typography>
      );
    }

    return tempUsers.map((e, i) => (
      <Card key={i} style={{ marginBottom: 10 }}>
        <CardContent>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography>
                <b>{e?.user?.name}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Local: <b>{e?.place?.name}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Unidade: <b>{e?.board?.name}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Válido até: <b>{moment(e.end).format("DD/MM/YYYY HH:mm")}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={e.used ? { color: "green" } : {}}>
                Usado: <b>{e.used ? "Sim" : "Não"}</b>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ justifyContent: "flex-end" }}>
          <IconButton
            onClick={() => {
              delTempUser(e._id);
            }}
          >
            <Delete />
          </IconButton>
        </CardActions>
      </Card>
    ));
  };

  const procHour = (value: number) => {
    value = value - new Date().getTimezoneOffset() / 60;
    const hour = Math.trunc(value);
    let minutes = ((value - hour) * 60).toFixed(0);
    //Verifiqua se o valor dos minutos é igual a 0
    minutes = minutes === "0" ? "00" : minutes;
    return `${hour}:${minutes}`;
  };

  const procDays = (value: number[]) => {
    const days: string[] = [];
    value.forEach((e) => {
      let pushVal = "";
      switch (e) {
        case 0:
          pushVal = "Dom.";
          break;
        case 1:
          pushVal = "Seg.";
          break;
        case 2:
          pushVal = "Ter.";
          break;
        case 3:
          pushVal = "Qua.";
          break;
        case 4:
          pushVal = "Qui.";
          break;
        case 5:
          pushVal = "Sex.";
          break;
        case 6:
          pushVal = "Sab.";
          break;
        default:
          pushVal = "Dom.";
          break;
      }
      days.push(pushVal);
    });
    return <b>{days.join(" , ")}</b>;
  };

  const renderRecUsers = () => {
    if (loading) {
      return (
        <div className={classes.center}>
          <CircularProgress />
        </div>
      );
    }

    if (recUsers.length === 0) {
      return (
        <Typography align="center" variant="h6">
          Nenhum usuário recorrente
        </Typography>
      );
    }

    return recUsers.map((e, i) => (
      <Card key={i} style={{ marginBottom: 10 }}>
        <CardContent>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography>
                <b>{e?.user?.name}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Local: <b>{e?.place?.name}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Unidade: <b>{e?.board?.name}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                De <b>{procHour(e?.startHour)}</b> até{" "}
                <b>{procHour(e?.endHour)}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>Dias: {procDays(e.days)}</Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ justifyContent: "flex-end" }}>
          <IconButton
            onClick={() => {
              delRecUser(e._id);
            }}
          >
            <Delete />
          </IconButton>
        </CardActions>
      </Card>
    ));
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleChangeTabIndex = (index: number) => {
    setSelectedTab(index);
  };

  const handleAlertClose = () => {
    alterAlert();
  };

  const fabs = [
    {
      color: "primary" as "primary",
      className: classes.fab,
      icon: <AddIcon />,
      label: "Add",
      tooltip: "Adicionar visitante",
    },
    {
      color: "secondary" as "secondary",
      className: classes.fab,
      icon: <AddIcon />,
      label: "Edit",
      tooltip: "Adicionar usuário recorrente",
    },
  ];

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showAlert}
        onClose={handleAlertClose}
        message={alert}
      >
        <Alert onClose={handleAlertClose} severity={alertSeverity}>
          {alert}
        </Alert>
      </Snackbar>
      <Dialog
        fullScreen
        open={showModal === 0}
        onClose={() => {
          setShowModal(null);
          formikTemp.resetForm();
        }}
        TransitionComponent={Transition}
      >
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <Tooltip title="Fechar" aria-label="Fechar">
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setShowModal(null);
                  setBoards([]);
                  setPlaces([]);
                  formikTemp.resetForm();
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" className={classes.dialogTitle}>
              Adicionar visitante
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
          style={{ padding: 20 }}
          spacing={2}
        >
          <Grid item xs={6}>
            <Select
              id="place"
              name="place"
              options={places}
              isLoading={loading}
              loadingMessage={() => "Carregando locais"}
              onChange={(option) => {
                formikTemp.setFieldValue("place", option?.value);
                loadBoards(option?.value);
                formikTemp.setFieldValue("board", null);
              }}
              //value={defaultOption}
              placeholder="Local"
              styles={selectStyles}
              noOptionsMessage={() => "Nenhum local encontrado"}
            />
            <FormHelperText
              error={
                formikTemp.touched.place && Boolean(formikTemp.errors.place)
              }
            >
              {formikTemp.touched.place && formikTemp.errors.place}
            </FormHelperText>
          </Grid>
          <Grid item xs={6}>
            <Select
              options={boards || []}
              id="board"
              name="board"
              onChange={(option) => formikTemp.setFieldValue("board", option)}
              value={formikTemp.values.board}
              placeholder="Unidade"
              styles={selectStyles}
              isLoading={loading}
              loadingMessage={() => "Carregando unidades"}
              noOptionsMessage={() => {
                if (!formikTemp.values.place) {
                  return "Selecione um local primeiro";
                }
                return "Nenhuma unidade encontrada";
              }}
            />
            <FormHelperText
              error={
                formikTemp.touched.board && Boolean(formikTemp.errors.board)
              }
            >
              {formikTemp.touched.board && formikTemp.errors.board}
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formikTemp.values.email}
              onChange={formikTemp.handleChange}
              error={
                formikTemp.touched.email && Boolean(formikTemp.errors.email)
              }
              helperText={formikTemp.touched.email && formikTemp.errors.email}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => formikTemp.handleSubmit()}
              color="secondary"
            >
              Adicionar
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        fullScreen
        open={showModal === 1}
        onClose={() => {
          setShowModal(null);
          formikRec.resetForm();
        }}
        TransitionComponent={Transition}
      >
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <Tooltip title="Fechar" aria-label="Fechar">
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setShowModal(null);
                  setBoards([]);
                  setPlaces([]);
                  formikRec.resetForm();
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" className={classes.dialogTitle}>
              Adicionar usuário recorrente
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
          style={{ padding: 20 }}
          spacing={2}
        >
          <Grid item xs={6}>
            <Select
              id="place"
              name="place"
              options={places}
              isLoading={loading}
              loadingMessage={() => "Carregando locais"}
              onChange={(option) => {
                formikRec.setFieldValue("place", option?.value);
                loadBoards(option?.value);
                formikRec.setFieldValue("board", null);
              }}
              //value={defaultOption}
              placeholder="Local"
              styles={selectStyles}
              noOptionsMessage={() => "Nenhum local encontrado"}
            />
            <FormHelperText
              error={formikRec.touched.place && Boolean(formikRec.errors.place)}
            >
              {formikRec.touched.place && formikRec.errors.place}
            </FormHelperText>
          </Grid>
          <Grid item xs={6}>
            <Select
              options={boards || []}
              id="board"
              name="board"
              onChange={(option) => formikRec.setFieldValue("board", option)}
              value={formikRec.values.board}
              placeholder="Unidade"
              styles={selectStyles}
              isLoading={loading}
              loadingMessage={() => "Carregando unidades"}
              noOptionsMessage={() => {
                if (!formikTemp.values.place) {
                  return "Selecione um local primeiro";
                }
                return "Nenhuma unidade encontrada";
              }}
            />
            <FormHelperText
              error={formikRec.touched.board && Boolean(formikRec.errors.board)}
            >
              {formikRec.touched.board && formikRec.errors.board}
            </FormHelperText>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="startTime"
              type="time"
              name="startTime"
              label="Hora de início"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              value={formikRec.values.startTime}
              onChange={formikRec.handleChange}
              error={
                formikRec.touched.startTime &&
                Boolean(formikRec.errors.startTime)
              }
              helperText={
                formikRec.touched.startTime && formikRec.errors.startTime
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="endTime"
              type="time"
              name="endTime"
              label="Hora de fim"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              value={formikRec.values.endTime}
              onChange={formikRec.handleChange}
              error={
                formikRec.touched.endTime && Boolean(formikRec.errors.endTime)
              }
              helperText={formikRec.touched.endTime && formikRec.errors.endTime}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              options={days_of_the_week || []}
              id="daysOfTheWeek"
              name="daysOfTheWeek"
              onChange={(option) => {
                const result: Array<number> = [];
                option.forEach((e) => {
                  result.push(e.value);
                });
                formikRec.setFieldValue("days", result);
              }}
              isMulti
              placeholder="Dias da semana"
              styles={selectStyles}
            />
            <FormHelperText
              error={formikRec.touched.days && Boolean(formikRec.errors.days)}
            >
              {formikRec.touched.days && formikRec.errors.days}
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formikRec.values.email}
              onChange={formikRec.handleChange}
              error={formikRec.touched.email && Boolean(formikRec.errors.email)}
              helperText={formikRec.touched.email && formikRec.errors.email}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => formikRec.handleSubmit()}
              color="secondary"
            >
              Adicionar
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <Typography variant="h5" className={classes.title}>
        Seus visitantes e usuários recorrentes
      </Typography>
      <div className={classes.tabsContainer}>
        <AppBar position="static" color="default">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Visitantes" />
            <Tab label="Recorrentes" />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={selectedTab}
          onChangeIndex={handleChangeTabIndex}
        >
          <TabPanel value={selectedTab} index={0} dir={theme.direction}>
            {renderVisitors()}
          </TabPanel>
          <TabPanel value={selectedTab} index={1} dir={theme.direction}>
            {renderRecUsers()}
          </TabPanel>
        </SwipeableViews>
      </div>
      {fabs.map((fab, index) => (
        <Zoom
          key={fab.color}
          in={selectedTab === index}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${
              selectedTab === index ? transitionDuration.exit : 0
            }ms`,
          }}
          unmountOnExit
        >
          <Tooltip title={fab.tooltip}>
            <Fab
              aria-label={fab.label}
              className={fab.className}
              color={fab.color}
              onClick={() => {
                loadPlaces();
                setShowModal(selectedTab);
              }}
            >
              {fab.icon}
            </Fab>
          </Tooltip>
        </Zoom>
      ))}
    </div>
  );
};

export default Visitors;
